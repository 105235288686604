import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import PostList from '../components/PostList'
import Layout from '../layouts'
import { capitalize } from '../utils/helpers'
import MainHeader from '../components/MainHeader'
import SEO from '../components/SEO'

const TagsTemplate = ({ data, pageContext }) => (
  <>
    <SEO />
    <Layout title="Tags">
      <MainHeader />
      <PostList
        data={data}
        header={`Category: ${capitalize(pageContext.name)}`}
      />
    </Layout>
  </>
)

TagsTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object,
  }),
  pageContext: PropTypes.shape({
    name: PropTypes.string,
  }),
}

TagsTemplate.defaultProps = {
  data: {},
  pageContext: { name: '' },
}

export default TagsTemplate

export const pageQuery = graphql`
  query($name: String!) {
    allMarkdownRemark(filter: { frontmatter: { tags: { eq: $name } } }) {
      edges {
        node {
          excerpt(pruneLength: 500)
          frontmatter {
            title
            date
            seo_title
            slug
            description
            tags
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
